import { Box, Container, Menu } from '@mui/material';
import React from 'react';
import {
  BrandingImg,
  BrandingLink,
  Hamburger,
  MainAppBar,
  MainMenuItem,
  MainToolbar,
  MenuButton,
  MenuLink,
  SubMenuLink,
} from './nav.styles';
import MenuIcon from '@mui/icons-material/Menu';

const Navbar: React.FC = () => {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <MainAppBar position="sticky" as="nav">
      <MainToolbar disableGutters>
        <Container
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          maxWidth={false}
        >
          <BrandingLink to="/">
            <BrandingImg alt="branding" src="/logo.png" />
          </BrandingLink>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <Hamburger
              size="large"
              onClick={handleOpenNavMenu}
              color="inherit"
              sx={{ color: 'white' }}
            >
              <MenuIcon />
            </Hamburger>
            <Menu
              id="menu-appbar"
              PopoverClasses={{ root: 'menu-subitem' }}
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MainMenuItem>
                <SubMenuLink to="/about">About</SubMenuLink>
              </MainMenuItem>
              <MainMenuItem>
                <SubMenuLink to="/sponsors">Sponsors</SubMenuLink>
              </MainMenuItem>
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <MenuLink to="/about">
              <MenuButton>About</MenuButton>
            </MenuLink>
            <MenuLink to="/sponsors">
              <MenuButton>Sponsors</MenuButton>
            </MenuLink>
          </Box>
        </Container>
      </MainToolbar>
    </MainAppBar>
  );
};

export default Navbar;
