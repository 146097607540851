import { CircularProgress } from '@mui/material';
import React from 'react';
import use3d from '../../hooks/use3d';
import { LoaderWrapper } from '../../styles/global.styles';
import { Feature } from '../../types/geojson.interface';

type Props = {
  canvas: React.MutableRefObject<HTMLCanvasElement | null>;
  feature: Feature;
};

const ThreeDWrapper: React.FC<Props> = ({ canvas, feature }) => {
  const { loading } = use3d({
    canvas,
    feature,
  });
  return (
    <>
      {loading && (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      )}
    </>
  );
};

export default ThreeDWrapper;
