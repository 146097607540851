import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FooterWrapper = styled('footer')(({ theme }) => ({
  background: 'rgba( 28, 28, 28, 0.25 )',
  boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
  backdropFilter: 'blur( 4px )',
  border: '1px solid rgba( 255, 255, 255, 0.18 )',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  position: 'fixed',
}));

export const FooterContainer = styled(Container)(({ theme }) => ({
  color: theme.palette.common.white,
  height: '60px',
  padding: '0 10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: 'unset !important',
  p: {
    whiteSpace: 'nowrap',
    fontSize: '14px',
  },
  '@media screen and (max-width: 768px)': {
    padding: '24px',
    height: 'auto',
    flexWrap: 'wrap',
  },
}));

export const PartnersWrapper = styled('div')`
  height: 60px;
  padding: 0;
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-width: 30%;
  @media screen and (max-width: 768px) {
    min-width: 100%;
    flex-wrap: wrap;
    height: auto;
  }
`;

export const PartnerLink = styled('a')`
  margin-left: 10px;
`;

export const PartnerImg = styled('img')`
  max-height: 60px;
  max-width: 250px;
  height: 100%;
  width: auto;
`;
