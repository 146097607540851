import React, { useEffect, useRef, useState } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { BingMaps, TileImage, Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import { GeoJson } from '../types/geojson.interface';
import Geometry from 'ol/geom/Geometry';
import GeoJSON from 'ol/format/GeoJSON';
import {
  Attribution,
  ScaleLine,
  defaults as defaultControls,
} from 'ol/control';
import { useNavigate } from 'react-router';

const useGlobalMap = ({
  mapRef,
  geojson,
}: {
  mapRef: React.RefObject<HTMLDivElement>;
  geojson: GeoJson;
}): void => {
  const [source, setSource] = useState<VectorSource<Geometry>>();
  const [loaded, setLoaded] = useState(false);
  const mapObjRef = useRef<Map | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (loaded) {
      source?.clear();
      source?.addFeatures(new GeoJSON().readFeatures(geojson));
    }
  }, [geojson]); // eslint-disable-line

  useEffect(() => {
    if (!mapObjRef.current && mapRef.current) {
      const attribution = new Attribution({
        collapsible: false,
      });
      const tileSource = new BingMaps({
        key: 'AiHNi7mayoLTslMt4YUgi_vNPcb8PPlitpd9-oAEWtlJel0ycNNNYQ-DBPhbKhJm',
        imagerySet: 'Aerial',
      });
      const tileImgSource = new TileImage({
        url: '/tiles/{z}/{x}/{y}.jpg',
        projection: 'EPSG:32636',
        attributions: '©NTT DATA, Included © Maxar Technologies, Inc.',
      });
      const zoomTileLayer = new TileLayer({
        source: tileImgSource,
        maxZoom: 23,
      });
      const baseView = new View({
        center: [4185385.9208, 3921831.0473],
        zoom: 8,
      });
      const map = new Map({
        controls: defaultControls().extend([
          attribution,
          new ScaleLine({
            units: 'metric',
            bar: false,
            steps: 4,
            minWidth: 140,
          }),
        ]),
        target: mapRef.current,
        layers: [
          new TileLayer({ source: tileSource, maxZoom: 19 }),
          zoomTileLayer,
        ],
        view: baseView,
      });
      const json = new GeoJSON().readFeatures(geojson);
      const vectorSource = new VectorSource({ features: json });
      setSource(vectorSource);

      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: new Style({
          image: new Icon({
            scale: 1,
            anchor: [0, 0],
            anchorXUnits: 'pixels',
            anchorYUnits: 'pixels',
            src: '/cube.svg',
          }),
        }),
      });
      map.addLayer(vectorLayer);
      if (geojson.features.length > 0) {
        map
          .getView()
          .fit(
            vectorLayer.getSource()?.getExtent() ?? baseView.calculateExtent(),
            {
              size: map.getSize(),
              maxZoom: 20,
            }
          );
      }
      map.on('click', (event) => {
        const feature = map.forEachFeatureAtPixel(event.pixel, (feature) => {
          return feature;
        });
        if (feature) {
          navigate(`/details/${feature.getProperties().name}`);
        }
      });
      map.on('pointermove', function (evt) {
        const hit = map.forEachFeatureAtPixel(evt.pixel, () => true);
        if (hit) {
          map.getTargetElement().style.cursor = 'pointer';
        } else {
          map.getTargetElement().style.cursor = '';
        }
      });
      setLoaded(true);
      mapObjRef.current = map;
    }
    return () => {};
  }, [mapRef]); // eslint-disable-line
};

export default useGlobalMap;
