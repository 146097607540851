import React from 'react';
import { useNavigate } from 'react-router';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import {
  CloseButton,
  SponsorImg,
  SponsorLink,
} from '../../styles/global.styles';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';

const SponsorsModal: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Dialog onClose={() => navigate('/')} open={true} maxWidth="md">
      <Helmet title="Sponsors" />
      <CloseButton onClick={() => navigate('/')}>
        <CloseIcon />
      </CloseButton>
      <DialogTitle>Sponsors</DialogTitle>
      <DialogContent>
        <SponsorLink
          target="_blank"
          rel="noreferrer"
          href="https://pcma.uw.edu.pl/en/"
        >
          <SponsorImg src="/pcma.png" alt="pcma" />
        </SponsorLink>
        <SponsorLink
          target="_blank"
          rel="noreferrer"
          href="https://honorfrostfoundation.org/"
        >
          <SponsorImg src="/HF.png" alt="hf" />
        </SponsorLink>
        <SponsorLink
          target="_blank"
          rel="noreferrer"
          href="https://ncn.gov.pl/"
        >
          <SponsorImg src="/ncn.png" alt="ncn" />
        </SponsorLink>
      </DialogContent>
    </Dialog>
  );
};

export default SponsorsModal;
