import React from 'react';
import {
  FooterContainer,
  FooterWrapper,
  PartnerImg,
  PartnerLink,
  PartnersWrapper,
} from './footer.styles';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterContainer>
        <p>
          &copy; {new Date().getFullYear()} Maciej Krawczyk & Mariusz Gwiazda
        </p>
        <PartnersWrapper>
          <p>Research Institutions:</p>
          <PartnerLink
            key="pcma"
            target="_blank"
            rel="noreferrer"
            href="https://pcma.uw.edu.pl/en/"
          >
            <PartnerImg src="/pcma.png" alt="pcma" />
          </PartnerLink>
          <PartnerLink
            key="hf"
            target="_blank"
            rel="noreferrer"
            href="https://honorfrostfoundation.org/"
          >
            <PartnerImg src="/HF.png" alt="HF" />
          </PartnerLink>
          <PartnerLink
            key="ncn"
            target="_blank"
            rel="noreferrer"
            href="https://ncn.gov.pl/"
          >
            <PartnerImg src="/ncn.png" alt="ncn" />
          </PartnerLink>
        </PartnersWrapper>
        <p>
          To view other parts of Berenike, go to{' '}
          <a
            style={{ textDecoration: 'underline' }}
            href="https://berenike.pcma.uw.edu.pl/virtual-sightseeing"
            target="_blank"
            rel="noreferrer"
          >
            Virtual Sightseeing
          </a>
          .
        </p>
      </FooterContainer>
    </FooterWrapper>
  );
};

export default Footer;
