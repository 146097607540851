import { createTheme } from '@mui/material';

export const MainTheme = createTheme({
  typography: {
    button: {
      fontFamily: 'Cabin, Arial',
    },
    fontFamily: 'Cabin, Arial',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Cabin';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
    MuiPaper: {
      styleOverrides: {
        root: 'background: #fff',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: 'border-radius: 0',
      },
    },
  },
  palette: {
    primary: {
      main: '#6B7280',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      paper: '#D2D6DC',
    },
  },
});
