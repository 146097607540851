import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { CloseButton } from '../../styles/global.styles';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from 'react-helmet-async';

const AboutModal: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Dialog onClose={() => navigate('/')} open={true} maxWidth="md">
      <Helmet title="About" />
      <CloseButton onClick={() => navigate('/')}>
        <CloseIcon />
      </CloseButton>
      <DialogTitle>About</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            In view of the fact that the Berenike archaeological site is located
            on military grounds, it remains unavailable for tourists, both from
            Egypt and from other parts of the world. Most of the artefacts
            unearthed during the excavation are located in Egyptian storage
            rooms and remain unavailable for viewing by interested persons.
            Although the results of archaeological works are regularly published
            as books and academic papers, information provided in them reaches
            mostly a small group of researchers. Taking into account the unique
            nature of Berenike and the vast array of discoveries made throughout
            the last twenty years, it is necessary to find a way to make this
            world heritage available to the widest possible audience.
          </Typography>
          <Typography>
            One of the methods of achieving this goal is a virtual museum with
            interactive maps and three-dimensional models. It makes Berenike
            accessible and raises awareness of its existence among local
            communities and the global society. The photographic scanning method
            enabling complete and thorough documentation of architectural
            objects and artefacts is used for the purpose. The resulting
            three-dimensional models are uploaded to a dedicated website. In
            this online environment, interested users are able to discover the
            remains of the ancient town without any additional software. The
            three-dimensional models may be freely rotated and zoomed in or out.
            These models will are provided with short descriptions with
            information about the dates and functions of individual buildings
            and artefacts. The visualisations is linked to an interactive map of
            the site to provide the context for our discoveries.
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default AboutModal;
