import { Button, DialogContent, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DialogBox = styled(DialogContent)({
  background: 'rgba( 28, 28, 28, 0.25 )',
  boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
  backdropFilter: 'blur( 4px )',
  border: '1px solid rgba( 255, 255, 255, 0.18 )',
  padding: '0',
  overflow: 'hidden',
  position: 'relative',
});

export const CanvasBox = styled('canvas')({
  width: '100%',
  height: '100%',
  cursor: 'grab',
  '&:active': {
    cursor: 'grabbing',
  },
});

export const CloseButton = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;

export const LoaderWrapper = styled('div')`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(28, 28, 28, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MapWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
`;

export const SponsorLink = styled('a')`
  display: block;
  text-align: center;
`;

export const SponsorImg = styled('img')`
  max-height: 300px;
  max-width: 100%;
  width: auto;
  height: auto;
  margin: auto;
`;

export const DescriptionButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  left: 20px;
`;

export const InfoButton = styled(IconButton)`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;
