import { Dialog, Tooltip, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  CanvasBox,
  CloseButton,
  DescriptionButton,
  DialogBox,
  InfoButton,
} from '../../styles/global.styles';
import { Feature, GeoJson } from '../../types/geojson.interface';
import CloseIcon from '@mui/icons-material/Close';
import ThreeDWrapper from '../three-d-wrapper/three-d-wrapper.component';
import { Helmet } from 'react-helmet-async';
import DescriptionIcon from '@mui/icons-material/Description';
import InfoIcon from '@mui/icons-material/Info';
interface Props {
  geojson: GeoJson;
}

const DetailsModal: React.FC<Props> = ({ geojson }) => {
  const { id } = useParams();
  const [current] = useState(
    geojson.features.find((f) => f.properties.name === id) ??
      ({
        type: 'Feature',
        properties: {
          name: '',
          description: '',
          model: '',
        },
        geometry: {
          type: 'Point',
          coordinates: [],
        },
      } as Feature)
  );
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  return (
    <Dialog fullScreen onClose={() => navigate('/')} open={true} maxWidth="xl">
      <Helmet title={current.properties.name} />
      <CloseButton onClick={() => navigate('/')}>
        <CloseIcon />
      </CloseButton>
      <DialogBox>
        <CanvasBox ref={canvasRef}></CanvasBox>
        <ThreeDWrapper canvas={canvasRef} feature={current} />
        <Tooltip
          title={<Typography>{current.properties.description}</Typography>}
          arrow
        >
          <DescriptionButton startIcon={<DescriptionIcon />} variant="outlined">
            Description
          </DescriptionButton>
        </Tooltip>
        <Tooltip
          title="Click and drag to rotate model, use mouse scroll to zoom in or out."
          arrow
        >
          <InfoButton>
            <InfoIcon />
          </InfoButton>
        </Tooltip>
      </DialogBox>
    </Dialog>
  );
};

export default DetailsModal;
