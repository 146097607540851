import React, { useRef } from 'react';
import Navbar from './components/navbar/navbar.component';
import Footer from './components/footer/footer.component';
import useGlobalMap from './hooks/useMap';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import geojson from './data.json';
import { GeoJson } from './types/geojson.interface';
import { Outlet, Route, Routes } from 'react-router';
import DetailsModal from './components/details-modal/details-modal.component';
import AboutModal from './components/about-modal/about-modal.component';
import SponsorsModal from './components/sponsors-modal/sponsors-modal.component';
import { MapWrapper } from './styles/global.styles';

function App() {
  const mapRef = useRef<HTMLDivElement>(null);
  useGlobalMap({
    mapRef,
    geojson: geojson as GeoJson,
  });

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | Virtual Berenike" title="Start">
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Navbar />
      <main style={{ height: '100vh' }}>
        <MapWrapper ref={mapRef}></MapWrapper>
      </main>
      <Footer />
      <Routes>
        <Route path="/" element={''} />
        <Route
          path="/details/:id"
          element={<DetailsModal geojson={geojson as GeoJson} />}
        />
        <Route path="/about" element={<AboutModal />} />
        <Route path="/sponsors" element={<SponsorsModal />} />
      </Routes>
      <Outlet />
    </HelmetProvider>
  );
}

export default App;
