import React, { useEffect, useState } from 'react';

import * as THREE from 'three';
import { Feature } from '../types/geojson.interface';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Box3, Vector3 } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

const use3d = ({
  canvas,
  feature,
}: {
  canvas: React.MutableRefObject<HTMLCanvasElement | null>;
  feature?: Feature;
}) => {
  const [loading, setLoading] = useState(true);
  async function Render() {
    let scene: THREE.Scene;
    if (canvas.current && feature) {
      scene = new THREE.Scene();
      const renderer = new THREE.WebGLRenderer({
        canvas: canvas.current,
        antialias: true,
      });

      renderer.setClearColor(0xcccccc);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.setPixelRatio(window.devicePixelRatio);

      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        0.1,
        1000
      );
      camera.position.set(2, 2, 2);

      const gridHelper = new THREE.GridHelper(10, 10, 0x888888, 0x444444);
      scene.add(gridHelper);
      const ambientLight = new THREE.AmbientLight(0xcccccc, 1);
      scene.add(ambientLight);

      const pointLight = new THREE.PointLight(0xffffff, 1.5);
      pointLight.position.set(250, 250, 250);
      camera.add(pointLight);

      scene.add(camera);

      const controls = new OrbitControls(camera, renderer.domElement);

      const render = () => renderer.render(scene, camera);

      controls.addEventListener('change', render);
      controls.minDistance = 1;
      controls.maxDistance = 50;
      controls.enablePan = true;
      controls.screenSpacePanning = true;

      try {
        const loader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('assets/draco/');
        loader.setDRACOLoader(dracoLoader);
        const gltf = await loader.loadAsync(
          feature.properties.model,
          function (xhr: any) {
            if (xhr.lengthComputable) {
              const percentComplete = (xhr.loaded / xhr.total) * 100;
              console.log(Math.round(percentComplete) + '% downloaded');
            }
          }
        );

        const object = gltf.scene;

        object.rotateX(THREE.MathUtils.degToRad(-90));

        const box = new Box3().setFromObject(object);
        const size = box.getSize(new Vector3());
        const length = box.getSize(new Vector3()).length();
        const center = box.getCenter(new Vector3());

        controls.reset();

        object.position.x += object.position.x - center.x;
        object.position.y += object.position.y - center.y;
        object.position.z += object.position.z - center.z;
        controls.maxDistance = length * 10;
        camera.near = length / 100;
        camera.far = length * 100;

        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs((maxDim / 4) * Math.tan(fov * 2));

        cameraZ *= 4; // zoom out a little so that objects don't fill the screen
        camera.position.set(cameraZ, cameraZ, cameraZ);
        camera.updateProjectionMatrix();

        scene.add(object);

        setTimeout(() => {
          setLoading(false);
        }, 3000);
        render();
      } catch (error) {
        console.log(error);
      }
    }
  }

  useEffect(() => {
    Render();
  }, [canvas.current]); // eslint-disable-line

  Render();

  return { loading };
};

export default use3d;
