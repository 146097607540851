import { styled } from '@mui/material/styles';
import { AppBar, Button, IconButton, MenuItem, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

export const BrandingLink = styled(Link)`
  height: 60px;
  width: 60px;
  margin: 5px;
  position: relative;
  display: block;
`;

export const BrandingImg = styled('img')`
  height: 60px;
  width: 60px;
`;

export const MainAppBar = styled(AppBar)(({ theme }) => ({
  background: 'rgba( 28, 28, 28, 0.25 )',
  boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
  backdropFilter: 'blur( 4px )',
  border: '1px solid rgba( 255, 255, 255, 0.18 )',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  position: 'fixed',
}));

export const MainToolbar = styled(Toolbar)`
  justify-content: space-between;
`;

export const MenuButton = styled(Button)(({ theme }) => ({
  borderRadius: 0,
  color: `${theme.palette.secondary.main}`,
  borderLeft: `1px solid ${theme.palette.primary.main}`,
  padding: '24px 24px',
  textTransform: 'none',

  whiteSpace: 'nowrap',

  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '16px',
  lineHeight: '19px',
  textAlign: 'center',
  textDecoration: 'none',

  '&:hover': {
    textDecoration: 'underline',
  },

  ul: {
    borderRadius: 0,
  },
}));

export const MenuLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: `${theme.palette.secondary.main}`,
  '&:hover': {
    textDecoration: 'underline',
  },
  '&:last-child': {
    borderRight: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const SubMenuLink = styled(Link)(({ theme }) => ({
  height: '30px',
  textDecoration: 'none',
  '&:last-child': {
    borderRight: `1px solid ${theme.palette.secondary.main}`,
  },

  '@media screen and (max-width: 768px)': {
    color: `${theme.palette.primary.main}`,
  },
}));

export const MainMenuItem = styled(MenuItem)(({ theme }) => ({
  minWidth: '110px',
  color: `${theme.palette.secondary.main}`,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const Hamburger = styled(IconButton)(({ theme }) => ({
  color: `${theme.palette.common.black}`,
}));
